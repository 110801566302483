import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../components/layouts/Layout';
import { Container, Row, Col, Input } from 'reactstrap';
import { ToastDismiss } from '../components/Common';
import InfoTooltip from '../components/Common/Tooltips/InfoTooltip';
import { fetchDetails, calculate } from '@a1-ict/fsbo/calculatorSlice';
import {
  // selectAnnualRate,
  selectGPR,
  selectMainInfo,
  selectMonthlyPayments,
  selectTooltipInfo,
} from '@a1-ict/fsbo/calculatorSlice/selectors';

import { toInteger } from 'lodash';
import { selectError } from '@a1-ict/core/dialogSlice';

const Calculator = () => {
  const [creditSize, setCreditSize] = useState('');
  const [creditYears, setCreditYears] = useState('');
  const [annualInterest, setAnnualInterest] = useState(2.99);
  const dispatch = useDispatch();

  const error = useSelector((state) => selectError(state, 'calculate_error'));

  // const annualRate = useSelector(selectAnnualRate);
  const annualGpr = useSelector(selectGPR);
  const monthlyPayment = useSelector(selectMonthlyPayments);
  const infoMain = useSelector(selectMainInfo);
  const infoDescription = useSelector(selectTooltipInfo);

  const calculateDetails = () => {
    dispatch(
      calculate({
        credit_size: creditSize || 0,
        credit_months: (creditYears || 0) * 12,
        annual_interest_rate: annualInterest,
      })
    );
  };

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    const response = await dispatch(fetchDetails());

    if (response?.payload?.ok) {
      setAnnualInterest(response.payload.data.annual_interest_rate);
    }
  };

  return (
    <Layout navColor="white" margined={true}>
      <Container fluid="md" className="pt-4">
        <ToastDismiss content={error} showToast={!!error} className="p-1 login-alert" />{' '}
        <Row>
          <Col className="mb-5 ">
            <div className="bg-white p-2 d-flex flex-column p-4 align-items-center">
              <h4>КРЕДИТЕН КАЛКУЛАТОР</h4>
              <div
                style={{ borderWidth: '7px', borderRadius: '1rem', maxWidth: '600px' }}
                className="my-3 border-primary px-4 py-2"
              >
                <Row className="  justify-content-center">
                  <Col xs={12} sm={8} className="">
                    <h5 className="font-weight-bolder ">Размер на кредита</h5>
                    <div className="password-container">
                      <Input
                        name="credit-size"
                        className="password-container_input"
                        aria-required={false}
                        type="number"
                        value={creditSize}
                        onChange={(e) => setCreditSize(toInteger(e.target.value))}
                      />
                      <div style={{ cursor: 'default' }} className="password-container_button">
                        евро
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} sm={8} className="my-2">
                    <h5 className="font-weight-bolder ">Срок на кредита</h5>
                    <div className="password-container">
                      <Input
                        name="credit-size"
                        className="password-container_input"
                        aria-required={false}
                        type="number"
                        value={creditYears}
                        onChange={(e) => setCreditYears(toInteger(e.target.value))}
                      />
                      <div style={{ cursor: 'default' }} className="password-container_button">
                        г.
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={4} className="my-2">
                    <h5 className="font-weight-bolder ">Лихва</h5>
                    {/* <div>{annualRate}</div> */}
                    <div className="password-container">
                      <Input
                        name="credit-size"
                        className="password-container_input"
                        aria-required={false}
                        value={annualInterest}
                        type="number"
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          if (!isNaN(value)) {
                            setAnnualInterest(value);
                          } else if (e.target.value === '') {
                            setAnnualInterest('');
                          }
                        }}
                      />
                      <div style={{ cursor: 'default' }} className="password-container_button">
                        %
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={8} className="my-2 ">
                    <div className=" d-flex align-items-center justify-content-between">
                      <h5 className="font-weight-bolder ">Месечна вноска (прогноза) </h5>

                      <InfoTooltip position="top" containerClassName={`pb-0`}>
                        {infoDescription && (
                          <div
                            className="text-dark border-primary px-2 pt-2"
                            dangerouslySetInnerHTML={{ __html: infoDescription }}
                          ></div>
                        )}
                      </InfoTooltip>
                    </div>
                    <div className="password-container">
                      <Input
                        name="credit-size"
                        className="password-container_input"
                        aria-required={false}
                        disabled
                        // readOnly
                        value={Math.floor(monthlyPayment) ?? ''}
                      />
                      <div style={{ cursor: 'default' }} className="password-container_button">
                        евро
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={4} className="my-2 ">
                    <div style={{ height: '39.5px' }}>
                      {' '}
                      <h5 className="font-weight-bolder ">ГПР</h5>
                    </div>

                    {/* <div>{annualRate}</div> */}
                    <div className="password-container">
                      <Input
                        name="gpr"
                        className="password-container_input"
                        aria-required={false}
                        value={annualGpr || ''}
                        disabled
                      />
                      <div style={{ cursor: 'default' }} className="password-container_button">
                        %
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={8} className="my-2 ">
                    {infoMain && (
                      <div
                        style={{ marginBottom: '-1rem' }}
                        dangerouslySetInnerHTML={{ __html: infoMain }}
                      ></div>
                    )}

                    <div>Безплатно и без ангажимент</div>
                    <div>Отнема 5 минути</div>
                    <div>Оферти до 48 часа</div>
                  </Col>
                  <Col xs={12} sm={4} className="my-2 d-flex justify-content-end align-items-end">
                    <button
                      disabled={!annualInterest || !creditSize || !creditYears}
                      className="btn animated px-4 py-2"
                      onClick={() => calculateDetails()}
                    >
                      <span className="font-weight-bold">ИЗЧИСЛИ </span>
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Calculator;
