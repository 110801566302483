import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import {AiFillInfoCircle} from '@react-icons/all-files/ai/AiFillInfoCircle';

const InfoTooltip = ({
  children,
  position,
  name,
  className = '',
  trigger = 'legacy',
  containerClassName,
  ...rest
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
 <div>
        <button id="trigger" className="btn shadow-none"> <AiFillInfoCircle size={24} color="#e9a081" /></button>
      <Tooltip
        placement={position}
        isOpen={tooltipOpen}
        toggle={toggle}
        target={'trigger'}
        trigger={trigger}
        innerClassName={containerClassName}
        {...rest}
      >
        {children}
      </Tooltip>
  </div>
  );
};


export default InfoTooltip;
